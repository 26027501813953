import { useMemo } from "react"

import * as shared from "probuild-shared"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import SubscriptionStatus from "components/views/subscription/SubscriptionStatus"
import SubscriptionOrders from "components/views/subscription/SubscriptionOrders"
import TextFieldDialog from "components/dialogs/TextFieldDialog"
import useSubscription from "model/subscription/useSubscription"
import { Box, Typography, styled } from "@mui/material"
import ConfirmationDialog from "components/dialogs/ConfirmationDialog"
import PaywallStatus from "components/views/subscription/PaywallStatus"
import LoadingModal from "components/views/generic/LoadingModal"

const WarningBox = styled(Box)(({ theme }) => ({
  border: "solid",
  backgroundColor: theme.palette.common.white,
  borderColor: theme.palette.warning.dark,
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2),
}))

const WarningText = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  padding: theme.spacing(2),
}))

const SubscriptionPage = ({ teamKey }: { teamKey: string }) => {
  const observer: shared.com.probuildsoftware.probuild.library.subscriptions.SubscriptionEventObserver =
    useMemo(() => {
      return {
        onAppleSubscriptionPurchaseEvent(
          event: shared.com.probuildsoftware.probuild.library.subscriptions.data.event.AppleSubscriptionPurchaseEvent
        ) {},
        onGoogleSubscriptionPurchaseEvent(
          event: shared.com.probuildsoftware.probuild.library.subscriptions.data.event.GoogleSubscriptionPurchaseEvent
        ) {},
        onTrialStartedEvent(
          event: shared.com.probuildsoftware.probuild.library.subscriptions.data.event.TrialStartedEvent
        ) {},
      }
    }, [])
  const [viewData, input] = useSubscription({
    teamKey,
    observer,
  })
  return (
    <TeamPageLayout title={viewData?.title} showLoadingSpinner={!viewData}>
      {viewData && (
        <>
          {!viewData.paywall.supportAgentInfo && (
            <WarningBox>
              <WarningText>
                The subscription details below are provided for information
                purposes only. To create or manage your subscription, please
                access the Settings menu in the Probuild app.
              </WarningText>
            </WarningBox>
          )}
          <SubscriptionStatus
            viewData={viewData.subscriptionStatus}
            onActionTapped={(actionKey) => {
              input?.onSubscriptionStatusOverflowMenuAction(actionKey)
            }}
          />
          <PaywallStatus
            viewData={viewData.paywall}
            onSelectedPlanChanged={(planKey) => {
              input?.onSelectedPlanChangedInitiated(planKey)
            }}
          />
          <SubscriptionOrders teamKey={teamKey} viewData={viewData.orders} />
          <ConfirmationDialog
            open={viewData.selectedPlanChangeDialog != null}
            dialogViewData={viewData.selectedPlanChangeDialog}
            onConfirm={() => input?.onSelectedPlanChangedConfirm()}
            onCancel={() => input?.onSelectedPlanChangedDismiss()}
          />
          <TextFieldDialog
            open={viewData.modifyTrialDialog != null}
            dialogViewData={viewData.modifyTrialDialog || null}
            onTextChange={(value) => {
              input?.modifyTrialUpdateTotalDays(value)
            }}
            onConfirm={() => {
              input?.modifyTrialConfirm()
            }}
            onCancel={() => {
              input?.modifyTrialDismiss()
            }}
          />
          <LoadingModal open={viewData.isSaveInProgress === true} />
        </>
      )}
    </TeamPageLayout>
  )
}

export default SubscriptionPage
