import { useCallback, useMemo } from "react"
import { Paper, Button, Box, List } from "@mui/material"
import DescriptionIcon from "@mui/icons-material/Description"
import { GetApp } from "@mui/icons-material"

import * as shared from "probuild-shared"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import DocumentsListItemView from "components/views/documents/DocumentsListItemView"
import LoadingView from "components/views/LoadingView"
import useDocumentAllList from "model/documents/list/useDocumentAllList"
import paths from "model/utils/paths"
import EmptyView from "components/views/EmptyView"
import LoadingModal from "components/views/generic/LoadingModal"
import useDownloadFile from "model/utils/useDownloadFile"

const DocumentsListPage = ({ teamKey }: { teamKey: string }) => {
  const downloadFile = useDownloadFile()
  const observer: shared.com.probuildsoftware.probuild.library.documents.DocumentAllListEventObserver =
    useMemo(() => {
      return {
        onExportCsvReady(filename: string, csvContent: string) {
          downloadFile(filename, csvContent, "text/csv")
        },
      }
    }, [downloadFile])
  const [viewData, input] = useDocumentAllList({
    teamKey,
    isSelecting: false,
    observer,
  })
  const loadMoreAtBottom = useCallback(() => {
    input?.loadMoreAtBottom()
  }, [input])
  const documentsListItems = viewData?.items?.asJsReadonlyArrayView() || []
  const hasMore = viewData?.status?.hasMoreAtBottom === true
  return (
    <TeamPageLayout
      title={viewData?.title}
      searchPlaceholder={viewData?.status.searchPlaceholder || ""}
      searchOnChange={(text) => input?.changeSearchQuery(text)}
      createButtons={[
        {
          to: paths.documentCreateEstimate(teamKey),
          text: viewData?.createEstimateButtonText,
        },
        {
          to: paths.documentCreateInvoice(teamKey),
          text: viewData?.createInvoiceButtonText,
        },
      ]}
      loadMoreAtBottom={loadMoreAtBottom}
      hasMore={hasMore}
      showLoadingSpinner={!viewData}
    >
      {viewData && (
        <>
          <Button
            startIcon={<GetApp />}
            onClick={() => {
              input?.exportCsv()
            }}
            sx={{ mb: 2 }}
          >
            {viewData.exportButtonText}
          </Button>

          <List component={Paper} disablePadding={true}>
            {documentsListItems.map((documentsListItem) => {
              return (
                <DocumentsListItemView
                  key={documentsListItem.viewKey}
                  teamKey={teamKey}
                  item={documentsListItem}
                  documentLink={paths.document(
                    teamKey,
                    documentsListItem.documentKey
                  )}
                />
              )
            })}
            {viewData.status.empty && (
              <Box sx={{ p: 2 }}>
                <EmptyView
                  icon={DescriptionIcon}
                  viewData={viewData.status.empty}
                />
              </Box>
            )}
          </List>
          {viewData.status.isLoadedAtBottom === false && (
            <LoadingView key={0} />
          )}
          <LoadingModal open={viewData.status.isPreparingExport} />
        </>
      )}
    </TeamPageLayout>
  )
}

export default DocumentsListPage
