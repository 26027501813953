import InfiniteScroll from "react-infinite-scroller"
import LoadingView from "components/views/LoadingView"
import FeedFilter from "components/views/feed/FeedFilter"
import FeedItemView from "components/views/feed/FeedItemView"
import useFeedListView from "model/feed/useFeedListView"
import LabeledSwitchView from "components/views/generic/LabeledSwitchView"
import ConfirmationDialog from "components/dialogs/ConfirmationDialog"
import useSupportAgentPaywall from "model/paywall/useSupportAgentPaywall"
import LoadingModal from "components/views/generic/LoadingModal"
import TeamPageLayout from "components/views/layouts/TeamPageLayout"

const SupportAgentFeedPage = () => {
  const feedListView = useFeedListView({ teamKey: null })
  const [viewData, input] = useSupportAgentPaywall()
  return (
    <TeamPageLayout title="Support Feed">
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {viewData?.toggle && (
            <LabeledSwitchView
              labelText={viewData?.toggle.label || ""}
              isOn={viewData.toggle.value}
              onChange={() => input?.initiateToggle()}
            />
          )}
          {viewData?.confirmationDialog && (
            <ConfirmationDialog
              open={viewData.confirmationDialog != null}
              onConfirm={() => input?.toggleConfirmed()}
              onCancel={() => input?.toggleCancelled()}
              dialogViewData={viewData.confirmationDialog}
            />
          )}
          {viewData?.errorDialog && (
            <ConfirmationDialog
              open={viewData.errorDialog != null}
              onConfirm={() => {}}
              onCancel={() => {}}
              dialogViewData={viewData.errorDialog}
            />
          )}
          <LoadingModal open={viewData?.isSaveInProgress === true} />
          <FeedFilter
            filter={feedListView.typeFilter}
            filterChanged={feedListView.changeTypeFilter}
          />
        </div>

        <InfiniteScroll
          pageStart={0}
          loadMore={feedListView.loadMoreAtBottom}
          hasMore={true}
          loader={<LoadingView />}
        >
          {feedListView.feedList?.items.map((feedItem) => {
            return (
              <FeedItemView
                key={feedItem.key}
                item={feedItem}
                showTeamName={true}
              />
            )
          })}
        </InfiniteScroll>
      </div>
    </TeamPageLayout>
  )
}

export default SupportAgentFeedPage
