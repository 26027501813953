import {
  Box,
  CardContent,
  Grid,
  styled,
  Typography,
  useTheme,
} from "@mui/material"

import * as shared from "probuild-shared"

import LabeledSectionCard from "components/views/generic/LabeledSectionCard"
import ViewDataToggleButtonGroup from "../generic/ViewDataToggleButtonGroup"

const InfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  paddingTop: theme.spacing(1),
}))

const InfoLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}))

const PaywallStatus = ({
  viewData,
  onSelectedPlanChanged,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.subscriptions.data.view.paywall.SubscriptionPaywallViewData
  onSelectedPlanChanged: (planKey: string) => void
}) => {
  const theme = useTheme()
  if (!viewData.supportAgentInfo) return null
  return (
    <LabeledSectionCard label="Paywall">
      <CardContent>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            paddingLeft: 1,
            paddingTop: 1,
          }}
        >
          <Typography variant="h6" color={theme.palette.secondary.main}>
            {viewData.supportAgentInfo.plansLabel}
          </Typography>
          <ViewDataToggleButtonGroup
            viewData={viewData.supportAgentInfo.plans}
            onChange={onSelectedPlanChanged}
          />
        </Box>
        <Grid container direction="row" spacing={1} pl={2} pt={2} gap={4}>
          <InfoBox>
            <InfoLabel>
              {viewData.supportAgentInfo.paywallStatusLabel}
            </InfoLabel>
            <InfoLabel>{viewData.supportAgentInfo.allowedUsersLabel}</InfoLabel>
            <InfoLabel>{viewData.supportAgentInfo.currentUsersLabel}</InfoLabel>
            <InfoLabel>{viewData.supportAgentInfo.trialStatusLabel}</InfoLabel>
          </InfoBox>
          <InfoBox>
            <Typography>
              {viewData.supportAgentInfo.paywallStatusValue}
            </Typography>
            <Typography>
              {viewData.supportAgentInfo.allowedUsersCount}
            </Typography>
            <Typography>
              {viewData.supportAgentInfo.currentUsersCount}
            </Typography>
            <Typography>
              {viewData.supportAgentInfo.trialStatusValue}
            </Typography>
          </InfoBox>
        </Grid>
      </CardContent>
    </LabeledSectionCard>
  )
}

export default PaywallStatus
