import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import * as shared from "probuild-shared"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import useSnackbar from "model/snackbar/useSnackbar"
import SaveButton from "components/views/generic/SaveButton"
import usePostEdit from "model/projects/usePostEdit"
import PostEditView from "components/views/projects/postEdit/PostEditView"
import useConfirmDiscardDialog from "model/dialogs/useConfirmDiscardDialog"
import DocumentListDialog from "./DocumentListDialog"
import ImageInput from "components/views/generic/ImageInput"
import FileInput from "components/views/generic/FileInput"

const PostEditDialog = ({
  teamKey,
  projectKey,
  postKey,
  actionKeyOnFirstLoad,
  isOpen,
  onCloseDialog,
}: {
  teamKey: string
  projectKey: string | undefined
  postKey: string | undefined | null
  actionKeyOnFirstLoad: string | null
  isOpen: boolean
  onCloseDialog: () => void
}) => {
  const showSnackbar = useSnackbar()
  const photoInputRef = useRef<HTMLInputElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [showDocumentListDialog, setShowDocumentListDialog] =
    useState<boolean>(false)
  const onAddPhoto = useCallback(() => {
    photoInputRef.current?.click()
  }, [photoInputRef])
  const onAddFile = useCallback(() => {
    fileInputRef.current?.click()
  }, [fileInputRef])
  const onAddDocument = useCallback(() => {
    setShowDocumentListDialog(true)
  }, [setShowDocumentListDialog])
  const observer = useMemo(() => {
    return {
      onShowSuccessSnackbar(message: string) {
        showSnackbar(message, "success")
      },
      onShowErrorSnackbar(message: string) {
        showSnackbar(message, "error")
      },
      onNavigateBack() {
        onCloseDialog()
      },
      onTakePhotos() {},
      onSelectPhotos() {
        onAddPhoto()
      },
      onCreateInvoice() {},
      onCreateEstimate() {},
      onSelectInvoiceOrEstimate() {
        onAddDocument()
      },
      onSelectFiles() {
        onAddFile()
      },
      onDownloadedFileReady(
        event: shared.com.probuildsoftware.probuild.library.projects.data.event.DownloadedFileEvent
      ) {},
    }
  }, [showSnackbar, onCloseDialog, onAddPhoto, onAddFile, onAddDocument])
  const onSaveClicked = () => input?.save()
  const onFilesSelected = async (
    files: shared.com.probuildsoftware.probuild.library.common.data.files.InputFile[]
  ) => {
    files.forEach((file) => {
      input?.addFileAttachment(file)
    })
  }
  const onImageSelected = async (
    file: shared.com.probuildsoftware.probuild.library.common.data.files.InputFile
  ) => {
    input?.addFileAttachment(file)
  }
  const onDocumentSelected = (documentKey: string | null) => {
    if (documentKey) {
      input?.addDocumentAttachment(documentKey)
    }
    setShowDocumentListDialog(false)
  }
  const [viewData, input] = usePostEdit({
    teamKey,
    projectKey,
    postKey,
    observer,
  })
  const [ConfirmDiscardDialog, onDiscardInitiated] = useConfirmDiscardDialog(
    viewData?.shouldConfirmDiscard,
    viewData?.discardDialog,
    onCloseDialog
  )
  useEffect(() => {
    input?.initiateActionKeyOnFirstLoad(actionKeyOnFirstLoad)
  }, [input, actionKeyOnFirstLoad])
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={onDiscardInitiated}
      title={viewData?.title}
      loadingProgress={viewData?.saveProgress}
      barItems={
        <>
          <SaveButton
            text={viewData?.saveButtonText}
            isEnabled={viewData?.isSaveButtonEnabled === true}
            onClick={onSaveClicked}
          />
        </>
      }
    >
      <PostEditView
        teamKey={teamKey}
        viewData={viewData}
        input={input}
        onAddDocument={onAddDocument}
        onAddFile={onAddFile}
        onAddPhoto={onAddPhoto}
      />
      {showDocumentListDialog && (
        <DocumentListDialog
          teamKey={teamKey}
          isOpen={showDocumentListDialog}
          onDocumentSelected={onDocumentSelected}
        />
      )}
      <ImageInput
        inputRef={photoInputRef}
        hidden={true}
        multiple={true}
        onFileChanged={onImageSelected}
      />
      <FileInput
        inputRef={fileInputRef}
        hidden={true}
        multiple={true}
        onFilesChanged={onFilesSelected}
      />
      <ConfirmDiscardDialog />
    </NavigationBarDialog>
  )
}

export default PostEditDialog
