import {
  Box,
  Button,
  CardContent,
  Chip,
  Grid,
  Typography,
  useTheme,
} from "@mui/material"

import * as shared from "probuild-shared"

import LabeledSectionCard from "components/views/generic/LabeledSectionCard"
import OverflowMenu from "../generic/OverflowMenu"

const SubscriptionStatus = ({
  viewData,
  onActionTapped,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.subscriptions.data.view.status.SubscriptionStatusViewData | null
  onActionTapped: (actionKey: string) => void
}) => {
  const theme = useTheme()
  const mainActions = viewData?.mainActions?.asJsReadonlyArrayView() || []
  return (
    viewData && (
      <LabeledSectionCard label={viewData.subtitle}>
        <CardContent>
          <Grid container direction="row" spacing={1}>
            <Grid item xs>
              <Grid container direction="row" columns={12} alignItems="center">
                <Grid item xs="auto">
                  <Typography variant="h5">{viewData.nameText}</Typography>
                </Grid>
                <Grid item xs="auto">
                  <Box px={2}>
                    {viewData.stateActiveText && (
                      <Chip
                        color="success"
                        size="small"
                        label={viewData.stateActiveText}
                      />
                    )}
                    {viewData.stateExpiredText && (
                      <Chip
                        variant="outlined"
                        color="error"
                        size="small"
                        label={viewData.stateExpiredText}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box pt={1}>
                    <Typography
                      variant="subtitle1"
                      color={theme.palette.text.secondary}
                    >
                      {viewData.featureDetailsText}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color={theme.palette.text.secondary}
                    >
                      {viewData.subscribeDetailsText}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs="auto">
              <OverflowMenu
                viewData={viewData.menu}
                onActionSelected={onActionTapped}
              />
            </Grid>
          </Grid>
          {mainActions.length > 0 && (
            <Grid container direction="row">
              <Box pt={2}>
                <Grid item xs="auto">
                  {mainActions.map((action) => (
                    <Button
                      key={action.actionKey}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        onActionTapped(action.actionKey)
                      }}
                    >
                      {action.actionLabel}
                    </Button>
                  ))}
                </Grid>
              </Box>
            </Grid>
          )}
        </CardContent>
      </LabeledSectionCard>
    )
  )
}

export default SubscriptionStatus
