import usePaywall from "model/paywall/usePaywall"
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
} from "@mui/material"
import { useMemo, useState } from "react"
import PaywallUpgradeDialog from "./PaywallUpgradeDialog"
import PaywallUserListDialog from "./PaywallUserListDialog"
import ConfirmationDialog from "./ConfirmationDialog"
import useAccountsList from "model/app/useAccountsList"
import useNavigateSafe from "model/navigation/useNavigateSafe"
import useSnackbar from "model/snackbar/useSnackbar"

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  marginBottom: theme.spacing(2),
  gap: theme.spacing(2),
}))

const SignOutButton = styled(Button)({
  alignSelf: "center",
  width: "fit-content",
})

const PaywallDialog = ({
  isUserAddArea,
  teamKey,
}: {
  isUserAddArea: boolean
  teamKey: string
}) => {
  const [navigate] = useNavigateSafe()
  const showSnackbar = useSnackbar()
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const observer = useMemo(() => {
    return {
      onNavigateBack() {
        navigate(-1)
      },
      onNavigateToDeleteUsers() {
        setDeleteDialogOpen(true)
      },
      onSignOutUser() {},
      onTrialStarted(message: string) {
        showSnackbar(message, "success")
      },
    }
  }, [navigate, setDeleteDialogOpen, showSnackbar])
  const [viewData, input] = usePaywall({ isUserAddArea, teamKey, observer })
  const [, userAccountsInput] = useAccountsList()
  const signOutUser = () => {
    input?.paywallSignOutConfirmed()
    userAccountsInput?.signOut()
  }
  const openUpgradeDialog = () => {
    setUpgradeDialogOpen(true)
    input?.paywallUpgradeInitiated()
  }
  const closeUpgradeDialog = () => {
    setUpgradeDialogOpen(false)
  }
  const openDeleteDialog = () => {
    input?.paywallDowngradeToBasic()
  }
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false)
  }
  return (
    <>
      {viewData?.dialog && (
        <Dialog fullWidth scroll="body" open={true}>
          <Box p={2}>
            <DialogTitle>
              <Typography variant="h4">{viewData.dialog.title}</Typography>
            </DialogTitle>
            <DialogContent>
              <Typography fontSize="large">
                {viewData.dialog.message}
              </Typography>
            </DialogContent>
            <StyledBox>
              <Button
                variant="outlined"
                fullWidth
                size="large"
                onClick={() => input?.paywallMoreInfo()}
                href={viewData.dialog.moreInfoUrl}
              >
                {viewData.dialog.moreInfoButtonText}
              </Button>
              <Button
                variant="contained"
                fullWidth
                size="large"
                onClick={openUpgradeDialog}
              >
                {viewData.dialog.upgradeNowButtonText}
              </Button>
              {viewData.dialog.startTrialButtonText && (
                <Button
                  variant="outlined"
                  fullWidth
                  size="large"
                  onClick={() => input?.paywallStartTrial()}
                >
                  {viewData.dialog.startTrialButtonText}
                </Button>
              )}
              <Button
                variant="outlined"
                fullWidth
                size="large"
                onClick={openDeleteDialog}
              >
                {viewData.dialog.downgradeToBasicButtonText}
              </Button>
              {viewData.dialog.signOutButtonText && (
                <SignOutButton
                  variant="text"
                  size="large"
                  onClick={() => input?.paywallSignOutInitiated()}
                >
                  {viewData.dialog.signOutButtonText}
                </SignOutButton>
              )}
            </StyledBox>
          </Box>
        </Dialog>
      )}
      {upgradeDialogOpen && (
        <PaywallUpgradeDialog
          open={upgradeDialogOpen}
          onClose={closeUpgradeDialog}
        />
      )}
      {deleteDialogOpen && (
        <PaywallUserListDialog
          teamKey={teamKey}
          open={deleteDialogOpen}
          onClose={closeDeleteDialog}
        />
      )}
      <ConfirmationDialog
        open={viewData?.dialog?.signOutConfirmationDialog != null}
        onConfirm={signOutUser}
        onCancel={() => input?.paywallSignOutCancelled()}
        dialogViewData={viewData?.dialog?.signOutConfirmationDialog}
      />
    </>
  )
}

export default PaywallDialog
