import { useEffect, useMemo, useState } from "react"
import {
  Box,
  Button,
  List,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material"

import * as shared from "probuild-shared"

import LoadingView from "components/views/LoadingView"
import TeamSelectorItemView from "components/views/team/TeamSelectorItemView"
import ErrorView from "components/views/ErrorView"
import EmptyView from "components/views/EmptyView"
import usePresenter from "model/presenter/usePresenter"
import useDependencyProvider from "model/utils/useDependencyProvider"
import TeamPageLayout from "components/views/layouts/TeamPageLayout"

const PhoneNumberLookupContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const PhoneNumberResultsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  marginLeft: theme.spacing(1),
}))

const SupportAgentTeamSelectorPage = () => {
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider) {
      return new shared.com.probuildsoftware.probuild.library.team.TeamSelectorPresenter(
        dependencyProvider
      )
    }
  }, [dependencyProvider])
  const [phoneNumber, setPhoneNumber] = useState(
    new URLSearchParams(window.location.search).get("phoneNumber") || "+"
  )
  const [submittedPhoneNumber, setSubmittedPhoneNumber] = useState(phoneNumber)

  const handleSearch = () => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set("phoneNumber", phoneNumber)
    window.history.replaceState(null, "", `?${searchParams.toString()}`)
    setSubmittedPhoneNumber(phoneNumber)
  }

  useEffect(() => {
    if (submittedPhoneNumber) {
      presenter?.input.loadTeams(submittedPhoneNumber)
    }
  }, [submittedPhoneNumber, presenter?.input])

  const [viewData] = usePresenter(presenter)
  return (
    <TeamPageLayout title="Business Lookup" showLoadingSpinner={!viewData}>
      <PhoneNumberLookupContainer>
        <TextField
          label="Phone Number"
          variant="outlined"
          size="small"
          value={phoneNumber}
          placeholder="Enter a valid phone number with country code"
          onChange={(e) => {
            const value = e.target.value.startsWith("+")
              ? e.target.value
              : "+" + e.target.value
            setPhoneNumber(value)
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch()
            }
          }}
          sx={{ width: "100%" }}
        />
        <Button variant="contained" onClick={handleSearch}>
          Search
        </Button>
      </PhoneNumberLookupContainer>
      <PhoneNumberResultsContainer>
        <Typography variant="h5">{viewData?.title}</Typography>
        <Typography>{viewData?.subtitle}</Typography>
      </PhoneNumberResultsContainer>
      {viewData?.status.isLoaded ? (
        <List component={Paper} disablePadding={true}>
          {viewData?.items?.asJsReadonlyArrayView()?.map((item) => {
            return <TeamSelectorItemView key={item.viewKey} item={item} />
          })}
          {viewData.empty && (
            <Box sx={{ p: 2 }}>
              <EmptyView icon={null} viewData={viewData?.empty} />
            </Box>
          )}
          {viewData?.status.isErrored === true && (
            <ErrorView description={viewData?.errorDescription || null} />
          )}
        </List>
      ) : (
        <LoadingView />
      )}
    </TeamPageLayout>
  )
}

export default SupportAgentTeamSelectorPage
