import { Paper, Box, List } from "@mui/material"
import GavelIcon from "@mui/icons-material/Gavel"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import ContractListItemView from "components/views/contracts/list/ContractListItemView"
import useContractList from "model/contracts/list/useContractList"
import paths from "model/utils/paths"
import EmptyView from "components/views/EmptyView"

const ContractListPage = ({ teamKey }: { teamKey: string }) => {
  const [viewData, input] = useContractList({ teamKey, isSelecting: false })
  const contractListItems = viewData?.items?.asJsReadonlyArrayView() || []
  return (
    <TeamPageLayout
      title={viewData?.title}
      searchPlaceholder={viewData?.searchHint}
      searchOnChange={(text) => input?.changeSearchQuery(text)}
      createButtons={[
        {
          to: paths.contractCreate(teamKey),
          text: viewData?.createButtonText,
        },
      ]}
      showLoadingSpinner={!viewData}
    >
      {viewData && (
        <List component={Paper} disablePadding={true}>
          {contractListItems.map((listItem) => {
            return (
              <ContractListItemView
                key={listItem.contractKey}
                teamKey={teamKey}
                item={listItem}
              />
            )
          })}
          {viewData.empty && (
            <Box sx={{ p: 2 }}>
              <EmptyView icon={GavelIcon} viewData={viewData.empty} />
            </Box>
          )}
        </List>
      )}
    </TeamPageLayout>
  )
}

export default ContractListPage
