import {
  Box,
  DialogContent,
  IconButton,
  styled,
  Typography,
} from "@mui/material"
import UserListSectionView from "components/views/users/list/UserListSectionView"
import usePaywallUserList from "model/users/paywall/usePaywallUserList"
import NavigationBarDialog from "./NavigationBarDialog"
import ConfirmationDialog from "./ConfirmationDialog"
import { red } from "@mui/material/colors"
import CloseIcon from "@mui/icons-material/Close"

const InstructionsBox = styled(Box)(({ theme }) => ({
  border: "solid",
  borderColor: theme.palette.error.main,
  backgroundColor: red[50],
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(3),
}))

const InstructionsText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.error.main,
  padding: theme.spacing(2),
}))

const PaywallUserListDialog = ({
  teamKey,
  open,
  onClose,
}: {
  teamKey: string
  open: boolean
  onClose: () => void
}) => {
  const [viewData, input] = usePaywallUserList({ teamKey })
  const sections = viewData?.sections?.asJsReadonlyArrayView() || []
  return (
    <NavigationBarDialog
      isOpen={open}
      onClose={onClose}
      title={viewData?.title}
      dynamicHeight
      barItems={
        <IconButton size="large" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
    >
      <DialogContent dividers>
        <InstructionsBox>
          <InstructionsText>{viewData?.instructions}</InstructionsText>
        </InstructionsBox>
        {sections.map((section) => {
          return (
            <UserListSectionView
              key={section.title}
              teamKey={teamKey}
              section={section}
              onUserDelete={(userKey: string) => {
                input?.deleteUserInitiated(userKey)
              }}
            />
          )
        })}
      </DialogContent>
      <ConfirmationDialog
        open={viewData?.deleteConfirmationDialog != null}
        onConfirm={() => input?.deleteUserConfirmed()}
        onCancel={() => input?.deleteUserCancelled()}
        dialogViewData={viewData?.deleteConfirmationDialog}
      />
      <ConfirmationDialog
        open={viewData?.completionDialog != null}
        onConfirm={() => {}}
        onCancel={() => {
          input?.allDeletionCompleted()
          onClose()
        }}
        dialogViewData={viewData?.completionDialog}
      />
    </NavigationBarDialog>
  )
}

export default PaywallUserListDialog
