import { Paper, Box, List } from "@mui/material"
import ListIcon from "@mui/icons-material/List"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import ItemListItemView from "components/views/items/list/ItemListItemView"
import useItemList from "model/items/list/useItemList"
import paths from "model/utils/paths"
import EmptyView from "components/views/EmptyView"

const ItemListPage = ({ teamKey }: { teamKey: string }) => {
  const [viewData, input] = useItemList({
    teamKey,
    isSelecting: false,
  })
  const itemListItems = viewData?.items?.asJsReadonlyArrayView() || []
  return (
    <TeamPageLayout
      title={viewData?.title}
      searchPlaceholder={viewData?.searchHint}
      searchOnChange={(text) => input?.changeSearchQuery(text)}
      createButtons={[
        {
          to: paths.itemCreate(teamKey),
          text: viewData?.createButtonText,
        },
      ]}
      showLoadingSpinner={!viewData}
    >
      {viewData && (
        <List component={Paper} disablePadding={true}>
          {itemListItems.map((item) => {
            return (
              <ItemListItemView
                key={item.itemKey}
                teamKey={teamKey}
                item={item}
              />
            )
          })}
          {viewData.empty && (
            <Box sx={{ p: 2 }}>
              <EmptyView icon={ListIcon} viewData={viewData.empty} />
            </Box>
          )}
        </List>
      )}
    </TeamPageLayout>
  )
}

export default ItemListPage
