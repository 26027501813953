import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import usePresenter from "model/presenter/usePresenter"
import useAppUser from "model/utils/useAppUser"

const usePaywall = ({
  isUserAddArea,
  teamKey,
  observer,
}: {
  isUserAddArea: boolean
  teamKey: string
  observer: shared.com.probuildsoftware.probuild.library.paywall.PaywallEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.paywall.data.view.PaywallViewData | null,
  shared.com.probuildsoftware.probuild.library.paywall.PaywallInput | null
] => {
  const dependencyProvider = useDependencyProvider()
  const appUser = useAppUser({ teamKey })
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser) {
      console.log(`Creating paywall presenter`)
      return new shared.com.probuildsoftware.probuild.library.paywall.PaywallPresenter(
        dependencyProvider,
        appUser,
        {
          isUserAddArea,
        }
      )
    }
  }, [dependencyProvider, isUserAddArea, appUser])
  return usePresenter(presenter, observer)
}

export default usePaywall
