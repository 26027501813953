import { Box, Button, Dialog, DialogActions, Typography } from "@mui/material"

const PaywallUpgradeDialog = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box m={3}>
        <Typography fontSize="large" sx={{ mt: 2, mb: 2 }}>
          Open the Probuild app on your mobile device to upgrade.
        </Typography>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default PaywallUpgradeDialog
