import React from "react"
import { styled, useTheme } from "@mui/material/styles"
import { Toolbar, Typography, useMediaQuery } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import IconButton from "@mui/material/IconButton"
import CollapsibleCreateButtons from "./CollapsibleCreateButtons"
import SearchBar from "./SearchBar"

const CenteredContentDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

const NavigationBarFlexContainerDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
})

const NavigationBarLeftDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginRight: theme.spacing(3),
  flexShrink: 0,
  [theme.breakpoints.down("sm")]: {
    flexShrink: 1,
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginRight: 0,
  },
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  display: "inline-block",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}))

const NavigationBarRightDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(1),
  flexGrow: 1,
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(3),
  [theme.breakpoints.up("md")]: { display: "none" },
  [theme.breakpoints.down("sm")]: { marginRight: theme.spacing(2) },
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  boxShadow: theme.shadows[1],
  justifyContent: "center",
  flexShrink: 0,
  position: "relative",
  zIndex: 1000,
  flexDirection: "column",
}))

const NavigationBar = ({
  className,
  title,
  fullWidth,
  barItems,
  createButtons,
  toggleNavigationDrawer,
  searchPlaceholder,
  searchOnChange,
  searchAutoFocus,
}: {
  className?: string
  title: string | null | undefined
  fullWidth?: boolean
  barItems?: React.ReactNode
  createButtons?: { to: string; text: string | undefined | null }[]
  toggleNavigationDrawer?: () => void
  searchPlaceholder?: string
  searchOnChange?: (text: string) => void
  searchAutoFocus?: boolean
}) => {
  const buttonsWithText = createButtons?.reduce(
    (result: { to: string; text: string }[], button) => {
      if (button.text) {
        result.push({ to: button.to, text: button.text })
      }
      return result
    },
    []
  )
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <StyledToolbar className={className} disableGutters={true}>
      <CenteredContentDiv sx={{ maxWidth: fullWidth ? "1400px" : "870px" }}>
        <NavigationBarFlexContainerDiv>
          <NavigationBarLeftDiv>
            {toggleNavigationDrawer && (
              <StyledIconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleNavigationDrawer}
              >
                <MenuIcon />
              </StyledIconButton>
            )}
            <StyledTitle variant="h6">{title}</StyledTitle>
          </NavigationBarLeftDiv>
          <NavigationBarRightDiv>
            {searchOnChange && !isMobileScreen && (
              <SearchBar
                placeholder={searchPlaceholder}
                onChange={(text: string) => searchOnChange(text)}
                autoFocus={searchAutoFocus}
              />
            )}
            {barItems}
            {buttonsWithText && (
              <CollapsibleCreateButtons buttons={buttonsWithText} />
            )}
          </NavigationBarRightDiv>
        </NavigationBarFlexContainerDiv>
        {searchOnChange && isMobileScreen && (
          <SearchBar
            placeholder={searchPlaceholder}
            onChange={(text: string) => searchOnChange(text)}
            autoFocus={true}
          />
        )}
      </CenteredContentDiv>
    </StyledToolbar>
  )
}

export default NavigationBar
