import { styled } from "@mui/material"
import NotFoundPage from "components/pages/NotFoundPage"
import SupportAgentFeedPage from "components/pages/SupportAgentFeedPage"
import SupportAgentTeamSelectorPage from "components/pages/SupportAgentTeamSelectorPage"
import SupportAgentNavigationDrawer from "components/views/app/SupportAgentNavigationDrawer"
import NavigationDrawerProvider from "contexts/providers/NavigationDrawerProvider"
import useAccountsList from "model/app/useAccountsList"
import paths from "model/utils/paths"
import { Routes, Route, Navigate, Outlet } from "react-router-dom"

const HorizontalStackDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  overflow: "hidden",
  height: "100%",
})

const VerticalStackDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
})

const SupportAgentRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<SupportAgentRootRoute />}>
        <Route path="feed" element={<SupportAgentFeedPage />} />
        <Route path="businesses" element={<SupportAgentTeamSelectorPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}

const SupportAgentRootRoute = () => {
  const [accounts] = useAccountsList()
  const currentAccountTeamKey = accounts?.currentAccount?.teamKey
  if (!accounts) {
    return <></>
  } else if (accounts.isSignedOut || !accounts.currentAccount) {
    return <Navigate to={paths.authPhone()} replace={true} />
  } else if (!accounts.isSupportAgent && currentAccountTeamKey) {
    return <Navigate to={paths.team(currentAccountTeamKey)} replace={true} />
  } else if (!accounts.isSupportAgent) {
    return <Navigate to={"/"} replace={true} />
  } else {
    return (
      <NavigationDrawerProvider>
        <VerticalStackDiv>
          <HorizontalStackDiv>
            <SupportAgentNavigationDrawer />
            <Outlet />
          </HorizontalStackDiv>
        </VerticalStackDiv>
      </NavigationDrawerProvider>
    )
  }
}

export default SupportAgentRoutes
