import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import { SxProps, Theme } from "@mui/system"

import * as shared from "probuild-shared"

const ViewDataToggleButtonGroup = ({
  className,
  viewData,
  onChange,
  sx,
}: {
  className?: string
  viewData: shared.com.probuildsoftware.probuild.library.common.data.view.ToggleButtonGroupViewData
  onChange: (identifier: string) => void
  sx?: SxProps<Theme>
}) => {
  const buttons = viewData.buttons?.asJsReadonlyArrayView()
  return (
    <ToggleButtonGroup
      className={className}
      size="small"
      value={viewData.selectedButtonIndex}
      exclusive
      onChange={(event, newSelectedButtonIndex) => {
        const selectedButton = buttons[newSelectedButtonIndex]
        if (selectedButton) {
          onChange(selectedButton.identifier)
        }
      }}
      sx={sx}
    >
      {buttons.map((button, index) => {
        return (
          <ToggleButton key={button.identifier} value={index}>
            {button.display}
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}

export default ViewDataToggleButtonGroup
