import ListIcon from "@mui/icons-material/List"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import UserListSectionView from "components/views/users/list/UserListSectionView"
import useUserList from "model/users/list/useUserList"
import paths from "model/utils/paths"
import EmptyView from "components/views/EmptyView"

const UserListPage = ({ teamKey }: { teamKey: string }) => {
  const [viewData, input] = useUserList({
    teamKey,
    isSelecting: false,
  })
  const sections = viewData?.sections?.asJsReadonlyArrayView() || []
  return (
    <TeamPageLayout
      title={viewData?.title}
      searchPlaceholder={viewData?.searchHint}
      searchOnChange={(text) => input?.changeSearchQuery(text)}
      createButtons={[
        {
          to: paths.userAdd(teamKey),
          text: viewData?.addButtonTitle,
        },
      ]}
      showLoadingSpinner={!viewData}
    >
      {viewData && (
        <>
          {sections.map((section) => {
            return (
              <UserListSectionView
                key={section.title}
                teamKey={teamKey}
                section={section}
              />
            )
          })}
          <EmptyView icon={ListIcon} viewData={viewData.empty} />
        </>
      )}
    </TeamPageLayout>
  )
}

export default UserListPage
