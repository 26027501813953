import { useEffect, useRef, useState } from "react"
import { styled } from "@mui/material/styles"
import { Button, Typography } from "@mui/material"
import ReactSignatureCanvas from "react-signature-canvas"

import * as shared from "probuild-shared"

import ImageView from "components/views/generic/ImageView"
import NavigationBarDialog from "components/dialogs/NavigationBarDialog"

const SignatureImageContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}))

const SignatureCanvasContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  justifyContent: "end",
  backgroundColor: theme.palette.background.paper,
  width: "100%",
}))

const SignatureImageView = styled(ImageView)(({ theme }) => ({
  width: "400px",
  height: "200px",
  objectFit: "contain",
  marginBottom: theme.spacing(2),
}))

const RepeatItemRow = ({
  documentAnswerKey,
  userKey,
  element,
  input,
}: {
  documentAnswerKey: string
  userKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
}) => {
  const [isSigning, setIsSigning] = useState<boolean>(false)
  const [signatureCanvas, setSignatureCanvas] =
    useState<ReactSignatureCanvas | null>(null)

  const file = element.files?.asJsReadonlyArrayView()?.find((x) => true) || null
  const onSignatureCompletion = () => {
    const base64Signature = signatureCanvas?.getTrimmedCanvas()?.toDataURL()
    if (base64Signature && signatureCanvas?.isEmpty() === false) {
      const signatureBlob = convertBase64ToBlob(base64Signature)
      const signatureUrl = URL.createObjectURL(signatureBlob)
      input.addFile(
        documentAnswerKey,
        element.elementKey,
        documentAnswerKey,
        new shared.com.probuildsoftware.probuild.library.common.data.files.InputFile(
          signatureUrl,
          createSignatureFilename(),
          "image/png",
          signatureBlob.size
        )
      )
    }
    setIsSigning(false)
  }
  const onSignatureClear = () => {
    signatureCanvas?.clear()
  }
  const signature = element.signature
  const changeButtonText = signature?.changeButtonText
  const removeButtonText = signature?.removeButtonText
  const containerRef = useRef(null)
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current && signatureCanvas) {
        const { offsetWidth, offsetHeight } = containerRef.current
        signatureCanvas.getCanvas().width = offsetWidth
        signatureCanvas.getCanvas().height = offsetHeight
      }
    }

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [signatureCanvas])
  const signatureCanvasWidth = "80vw"
  const signatureCanvasHeight = "40vh"
  const signatureCanvasPadding = 30

  return (
    <div>
      <SignatureImageContainerDiv>
        {file && (
          <SignatureImageView
            file={file}
            alt={element.labelText || ""}
            width={400}
            height={200}
          />
        )}
        <div>
          {changeButtonText && (
            <Button
              onClick={() => setIsSigning(true)}
              variant="contained"
              color="primary"
            >
              {changeButtonText}
            </Button>
          )}
          {removeButtonText && (
            <Button
              sx={{ ml: 1 }}
              onClick={() =>
                file?.info.path && input.removeFile(file?.info.path)
              }
              color="primary"
            >
              {removeButtonText}
            </Button>
          )}
        </div>
      </SignatureImageContainerDiv>
      <NavigationBarDialog
        title={"Signature"}
        isOpen={isSigning}
        onClose={onSignatureCompletion}
        dynamicHeight
        barItems={
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={onSignatureClear}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onSignatureCompletion}
            >
              Done
            </Button>
          </>
        }
      >
        <SignatureCanvasContainerDiv
          ref={containerRef}
          style={{
            height: signatureCanvasHeight,
            width: signatureCanvasWidth,
          }}
        >
          <ReactSignatureCanvas
            ref={setSignatureCanvas}
            penColor="black"
            canvasProps={{
              width: "100%",
              height: "100%",
            }}
          />
        </SignatureCanvasContainerDiv>
        <SignatureLineBlock
          canvasPadding={signatureCanvasPadding}
          signatureLabel={"Sign Here"}
        />
      </NavigationBarDialog>
    </div>
  )
}

const SignatureLineContainerDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
})

const SignatureLineDiv = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  height: "4px",
}))

const SignatureLineTypographyDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
})

const SignatureLineTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.light,
  userSelect: "none",
}))

const SignatureLineBlock = ({
  canvasPadding,
  signatureLabel,
}: {
  canvasPadding: number
  signatureLabel: string
}) => {
  return (
    <SignatureLineContainerDiv
      style={{
        paddingLeft: canvasPadding,
        paddingRight: canvasPadding,
        paddingBottom: canvasPadding,
      }}
    >
      <SignatureLineDiv />
      <SignatureLineTypographyDiv>
        <SignatureLineTypography variant="h6">X</SignatureLineTypography>
        <SignatureLineTypography
          style={{ width: "100%", textAlign: "center" }}
          variant="h6"
        >
          {signatureLabel}
        </SignatureLineTypography>
      </SignatureLineTypographyDiv>
    </SignatureLineContainerDiv>
  )
}

const convertBase64ToBlob = (base64Image: string) => {
  const parts = base64Image.split(";base64,")
  const imageType = parts[0].split(":")[1]
  const decodedData = window.atob(parts[1])
  const uInt8Array = new Uint8Array(decodedData.length)
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i)
  }
  return new Blob([uInt8Array], { type: imageType })
}

const createSignatureFilename = (): string => {
  const now = new Date()
  const year = now.getFullYear()
  const month = `${now.getMonth() + 1}`.padStart(2, "0")
  const day = `${now.getDate()}`.padStart(2, "0")
  const hour = `${now.getHours()}`.padStart(2, "0")
  const minute = `${now.getMinutes()}`.padStart(2, "0")
  const second = `${now.getSeconds()}`.padStart(2, "0")
  return `IMG_${year}${month}${day}_${hour}${minute}${second}.png`
}

export default RepeatItemRow
