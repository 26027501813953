import { Add } from "@mui/icons-material"
import { Button, useMediaQuery, useTheme } from "@mui/material"
import { Link } from "react-router-dom"

const CreateButton = ({
  text,
  to,
  onClick,
}: {
  text: string | null | undefined
  to?: string
  onClick?: () => void
}) => {
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"))
  if (!text) return null
  return (
    <Button
      component={to ? Link : "button"}
      to={to}
      onClick={onClick}
      variant="contained"
      color="primary"
      sx={{ flexShrink: 0 }}
    >
      {isMobileScreen ? <Add /> : text}
    </Button>
  )
}

export default CreateButton
