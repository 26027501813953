import { styled } from "@mui/material/styles"
import { Dialog } from "@mui/material"

import NavigationBar from "components/views/generic/NavigationBar"
import LoadingModal from "components/views/generic/LoadingModal"

const ScrollContainer = styled("div")({
  maxHeight: "60%",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
})

const NavigationBarDialog = ({
  isOpen,
  onClose,
  title,
  dynamicHeight,
  scroll,
  barItems,
  children,
  loadingProgress,
  searchPlaceholder,
  searchOnChange,
  searchAutoFocus,
}: {
  isOpen: boolean
  onClose: () => void
  title: string | null | undefined
  dynamicHeight?: boolean
  scroll?: boolean
  barItems?: React.ReactNode
  children: React.ReactNode
  loadingProgress?: number | null
  searchPlaceholder?: string
  searchOnChange?: (text: string) => void
  searchAutoFocus?: boolean
}) => {
  return (
    <Dialog
      fullWidth
      scroll={scroll ? "paper" : "body"}
      open={isOpen}
      onClose={onClose}
    >
      <NavigationBar
        barItems={barItems}
        title={title}
        searchPlaceholder={searchPlaceholder}
        searchOnChange={
          searchOnChange && ((text: string) => searchOnChange(text))
        }
        searchAutoFocus={searchAutoFocus}
      />
      <ScrollContainer style={dynamicHeight ? {} : { height: "60%" }}>
        {children}
      </ScrollContainer>
      <LoadingModal
        open={loadingProgress !== null && loadingProgress !== undefined}
        value={loadingProgress}
      />
    </Dialog>
  )
}

export default NavigationBarDialog
