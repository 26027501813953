import React from "react"
import { styled } from "@mui/material/styles"
import { Link } from "react-router-dom"

import useAuthIntentSelectionViewData from "model/auth/useAuthIntentSelectionViewData"
import { Toolbar } from "@mui/material"

const RootDiv = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[1],
  justifyContent: "space-between",
  flexShrink: 0,
  position: "relative",
  zIndex: 1000,
}))

const HomeLink = styled(Link)({
  display: "inline-flex",
  alignItems: "center",
  padding: "10px 0px",
  textDecoration: "none",
})

const ProbuildBrandName = styled("span")(({ theme }) => ({
  fontFamily: "Raleway, Open Sans, Helvetica, Arial, sans-serif",
  fontSize: 20,
  color: theme.palette.common.white,
  textTransform: "uppercase",
  paddingLeft: 10,
  paddingTop: 5,
}))

const ProbuildLogoImage = styled("img")({
  maxHeight: 30,
})

const ProbuildNavigationBar = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const viewData = useAuthIntentSelectionViewData()
  return (
    <RootDiv>
      <HomeLink to="/">
        <ProbuildLogoImage src="/logoTransparent.png" alt="Probuild" />
        <ProbuildBrandName>{viewData?.title || ""}</ProbuildBrandName>
      </HomeLink>
      <div>{children}</div>
    </RootDiv>
  )
}

export default ProbuildNavigationBar
