import { useContext } from "react"
import { styled } from "@mui/material/styles"
import { Box, Divider, Drawer, List, Typography } from "@mui/material"
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed"
import { PersonSearch } from "@mui/icons-material"

import NavigationDrawerItem from "components/views/app/NavigationDrawerItem"
import AccountButton from "components/views/app/AccountButton"
import paths from "model/utils/paths"
import NavigationDrawerContext from "contexts/NavigationDrawerContext"

const DrawerContentDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
})

const AccountDiv = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
}))

const BrandingDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.error.main,
}))

const ProbuildLogoImage = styled("img")({
  height: "35px",
  objectFit: "contain",
})

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "large",
  color: theme.palette.common.white,
  margin: theme.spacing(0.5),
}))

const RootDiv = styled("div")(({ theme }) => ({
  width: drawerWidth,
  height: "100%",
  overflow: "hidden",
  flexShrink: 0,
  backgroundColor: theme.palette.error.main,
}))

const StyledList = styled(List)(({ theme }) => ({
  width: "100%",
  paddingTop: 0,
  paddingBottom: 0,
  backgroundColor: theme.palette.error.main,
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  opacity: 0.3,
}))

const drawerWidth = 260

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { flexShrink: 0 },
  [theme.breakpoints.up("md")]: { width: drawerWidth },
}))

const TemporaryDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: { display: "block" },
  [theme.breakpoints.up("sm")]: { display: "block" },
  [theme.breakpoints.up("md")]: { display: "none" },
  "& .MuiDrawer-paper": {
    boxSizing: "content-box",
    width: drawerWidth,
    position: "relative",
  },
}))

const PermanentDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: { display: "none" },
  [theme.breakpoints.up("sm")]: { display: "none" },
  [theme.breakpoints.up("md")]: { display: "block" },
  "& .MuiDrawer-paper": {
    boxSizing: "content-box",
    width: drawerWidth,
    position: "relative",
  },
}))

const SupportAgentNavigationDrawer = () => {
  const { openNavigationDrawer, toggleNavigationDrawer } = useContext(
    NavigationDrawerContext
  )

  const NavigationDrawerExpanded = (
    <RootDiv>
      <DrawerContentDiv>
        <div>
          <AccountDiv>
            <AccountButton />
          </AccountDiv>
          <StyledDivider />
          <StyledList>
            <NavigationDrawerItem
              title="Support Feed"
              icon={DynamicFeedIcon}
              href={paths.supportAgentFeed()}
            />
            <NavigationDrawerItem
              title="Business Lookup"
              icon={PersonSearch}
              href={paths.supportAgentTeamSelector()}
            />
          </StyledList>
          <StyledDivider />
        </div>
        <div>
          <BrandingDiv>
            <ProbuildLogoImage src="/logoTransparent.png" alt="Probuild" />
            <StyledTypography>Probuild</StyledTypography>
          </BrandingDiv>
        </div>
      </DrawerContentDiv>
    </RootDiv>
  )

  return (
    <StyledBox component="nav">
      <TemporaryDrawer
        variant="temporary"
        ModalProps={{
          keepMounted: true,
        }}
        open={openNavigationDrawer}
        onClose={toggleNavigationDrawer}
        onClick={toggleNavigationDrawer}
      >
        {NavigationDrawerExpanded}
      </TemporaryDrawer>
      <PermanentDrawer variant="permanent" open>
        {NavigationDrawerExpanded}
      </PermanentDrawer>
    </StyledBox>
  )
}

export default SupportAgentNavigationDrawer
